var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "workbench" }, [
    _c("div", { staticClass: "workbench-content" }, [
      _c(
        "ul",
        { staticClass: "workbench-cart clearfix" },
        _vm._l(_vm.cardData, function (item, index) {
          return _c(
            "li",
            { key: index, class: ["my-card", `card-buy${index}`] },
            [
              _c("div", { staticClass: "num" }, [
                _vm._v(_vm._s(item.orderCountSum)),
              ]),
              _c("div", { staticClass: "type" }, [
                _vm._v(_vm._s(item.orderType) + "订单"),
              ]),
              _c("div", { staticClass: "price" }, [
                _vm._v(_vm._s(_vm._f("formatPrice")(item.orderAmtSum))),
              ]),
              _c(
                "div",
                { staticStyle: { "font-size": "15px", opacity: "0.8" } },
                [_vm._v(_vm._s(item.orderType) + "订单总金额(元)")]
              ),
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "workbench-flower" }, [
        _c("div", { staticClass: "day" }, [_vm._v(_vm._s(_vm.getDay))]),
        _c("p", [_vm._v(_vm._s(_vm.getTime))]),
      ]),
    ]),
    _c("div", { staticClass: "task-content" }, [
      _c(
        "div",
        { class: ["task-left", _vm.accountType == "BUY" ? "BUY" : "SELL"] },
        [
          _c("p", [
            _vm._v(" 有 "),
            _c("span", [_vm._v(_vm._s(_vm.taskData.length))]),
            _vm._v(" 个待办任务 "),
            _c(
              "span",
              { staticClass: "listMore", on: { click: _vm.listMoreTo } },
              [_vm._v("更多 >")]
            ),
          ]),
          _c(
            "el-table",
            {
              staticClass: "left-table",
              staticStyle: { width: "100%" },
              attrs: {
                "row-class-name": "task-row",
                "header-cell-style": {
                  "font-size": "17px",
                  "text-align": "center",
                },
                "cell-style": { "text-align": "center" },
                data: _vm.taskData,
              },
              on: {
                "cell-mouse-enter": _vm.mouseEnterTask,
                "cell-mouse-leave": _vm.mouseLeaveTask,
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "num", label: "序号", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "addTime", label: "时间", width: "210" },
              }),
              _c("el-table-column", {
                attrs: { prop: "orderName", label: "产品信息", width: "240" },
              }),
              _c("el-table-column", {
                attrs: { prop: "actualPrice", label: "订单金额", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" ￥" + _vm._s(scope.row.actualPrice) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "orderType", label: "订单类型", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.orderMsg(scope.row.orderType)) +
                            "订单 "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "orderType", label: "信息状态", width: "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.approveStatus == "1"
                                ? "待审核"
                                : "提交信息"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "orderStatus", label: "订单状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.id !== _vm.taskId
                          ? _c("span", { staticClass: "mouseStyle" }, [
                              _vm._v(_vm._s(_vm.orderStatusMsg(scope.row))),
                            ])
                          : _c(
                              "el-button",
                              {
                                style:
                                  _vm.accountType == "BUY"
                                    ? "color:#0c95fe"
                                    : "color:#5d5dd2",
                                on: {
                                  click: function ($event) {
                                    return _vm.linkTo(scope.row)
                                  },
                                },
                              },
                              [_vm._v("处理")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "task-right" }, [
        _c(
          "ul",
          [
            _c("li", [
              _c(
                "p",
                { staticClass: "title" },
                [
                  _c("span", [_vm._v("消息动态")]),
                  _c(
                    "router-link",
                    {
                      staticClass: "right-more",
                      attrs: {
                        to: {
                          path: "/User/MsgList",
                          query: { customer: this.accountType },
                        },
                      },
                    },
                    [_vm._v("更多 >")]
                  ),
                ],
                1
              ),
            ]),
            _vm._l(_vm.msgData, function (item, index) {
              return _c(
                "li",
                { key: index, class: item.readStatus === "2" ? "round" : "" },
                [
                  _c("p", { staticClass: "time" }, [
                    _vm._v(_vm._s(item.createTime)),
                  ]),
                  _c("p", { staticClass: "actr" }, [
                    _vm._v(_vm._s(item.msgContent)),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }