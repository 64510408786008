<template>
  <div class="workbench">
    <div class="workbench-content">
      <ul class="workbench-cart clearfix">
        <li :class="['my-card', `card-buy${index}`]" v-for="(item, index) in cardData" :key="index">
          <div class="num">{{ item.orderCountSum }}</div>
          <div class="type">{{ item.orderType }}订单</div>
          <div class="price">{{ item.orderAmtSum | formatPrice }}</div>
          <div style="font-size: 15px; opacity: 0.8">{{ item.orderType }}订单总金额(元)</div>
        </li>
      </ul>
      <div class="workbench-flower">
        <div class="day">{{ getDay }}</div>
        <p>{{ getTime }}</p>
      </div>
    </div>
    <div class="task-content">
      <div :class="['task-left', accountType == 'BUY' ? 'BUY' : 'SELL']">
        <p>
          有
          <span>{{ taskData.length }}</span>
          个待办任务
          <span class="listMore" @click="listMoreTo">更多 ></span>
        </p>
        <el-table
          @cell-mouse-enter="mouseEnterTask"
          @cell-mouse-leave="mouseLeaveTask"
          row-class-name="task-row"
          :header-cell-style="{'font-size': '17px', 'text-align': 'center'}"
          :cell-style="{'text-align': 'center'}"
          class="left-table"
          :data="taskData"
          style="width: 100%"
        >
          <el-table-column prop="num" label="序号" width="70">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="addTime" label="时间" width="210"> </el-table-column>
          <el-table-column prop="orderName" label="产品信息" width="240"> </el-table-column>
          <el-table-column prop="actualPrice" label="订单金额" width="200">
            <template slot-scope="scope"> ￥{{ scope.row.actualPrice }} </template>
          </el-table-column>
          <el-table-column prop="orderType" label="订单类型" width="120">
            <template slot-scope="scope"> {{ orderMsg(scope.row.orderType) }}订单 </template>
          </el-table-column>
          <el-table-column prop="orderType" label="信息状态" width="130">
            <template slot-scope="scope"> {{ scope.row.approveStatus == '1' ? '待审核' : '提交信息' }} </template>
          </el-table-column>
          <el-table-column prop="orderStatus" label="订单状态">
            <template slot-scope="scope">
              <span v-if="scope.row.id !== taskId" class="mouseStyle">{{ orderStatusMsg(scope.row) }}</span>
              <el-button :style="accountType == 'BUY' ? 'color:#0c95fe' : 'color:#5d5dd2'" @click="linkTo(scope.row)" v-else>处理</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="task-right">
        <ul>
          <li>
            <p class="title">
              <span>消息动态</span>
              <router-link class="right-more" :to="{path: '/User/MsgList', query: {customer: this.accountType}}">更多 ></router-link>
            </p>
          </li>
          <li :class="item.readStatus === '2' ? 'round' : ''" v-for="(item, index) in msgData" :key="index">
            <p class="time">{{ item.createTime }}</p>
            <p class="actr">{{ item.msgContent }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {permission} from '@/modules/mixin/permission-mixin.js';
import {static4OrderAmt, getMessgeList, mybuyderOrderList, mysellerOrderList} from '../api';
export default {
  data() {
    return {
      cardData: [
        {
          orderCountSum: 0,
          orderType: '竞买',
          orderAmtSum: 0
        },
        {
          orderCountSum: 0,
          orderType: '竞卖',
          orderAmtSum: 0
        },
        {
          orderCountSum: 0,
          orderType: '竞标',
          orderAmtSum: 0
        }
      ],
      msgData: [],
      userInfo: {},
      taskData: [],
      taskId: ''
    };
  },
  mixins: [permission],
  created() {
    if (localStorage.getItem('MenuType')) this.$store.commit('changeMenuType', localStorage.getItem('MenuType'));
    if (this.accountType === 'BUY') {
      this.getMybuyderOrderList();
    } else {
      this.getMysellerOrderList();
    }
    this.userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    this.getStatic4OrderAmt();
    this.getMessgeList();
  },
  computed: {
    getDay() {
      return this.$moment().date();
    },
    getTime() {
      return this.$moment().format('YYYY年MM月');
    },
    accountType() {
      return this.$store.getters['getterMenuType'];
    }
  },
  watch: {
    accountType: function(val) {
      this.getStatic4OrderAmt();
      if (val === 'BUY') {
        this.getMybuyderOrderList();
      } else {
        this.getMysellerOrderList();
      }
    }
  },
  methods: {
    mouseEnterTask(row) {
      this.taskId = row.id;
    },
    mouseLeaveTask() {
      this.taskId = '';
    },
    linkTo(item) {
      if (!this.isPermission(item.contractStatus == 'signing' ? {order_status: 'DQS'} : item, this.accountType == 'BUY' ? 'buyder' : 'sellder')) {
        this.$message({
          message: '当前账号无权限',
          type: 'warning',
          center: true
        });
        return;
      }
      let myObj = {
        orderNum: item.orderSn,
        orderid: item.id,
        orderType: item.orderType,
        customer: this.$store.getters['getterMenuType'] == 'BUY' ? 'buyder' : 'sellder',
        isHandleShow: true,
        tciId: item.contractId
      };
      this.$router.push({path: '/User/TransactionDetail', query: myObj});
    },
    listMoreTo() {
      this.$router.push({path: '/User/taskList'});
    },
    orderMsg(status) {
      if (status === 'AU') {
        return '竞卖';
      } else if (status === 'RE') {
        return '竞买';
      } else if (status === 'BI') {
        return '竞标';
      }
    },
    orderStatusMsg(row) {
      if (row.contractStatus == 'signing') return '待签署';
      if (row.orderStatus === 'DFH') {
        return '待发货';
      } else if (row.orderStatus === 'DSH') {
        return '待签收';
      } else if (row.orderStatus === 'DKP') {
        return '待开发票';
      } else if (row.orderStatus === 'DZF') {
        return '待支付';
      } else if (row.orderStatus === 'ZFC') {
        return '支付待确认';
      } else if (row.orderStatus === 'END') {
        return '交易完成';
      } else if (row.orderStatus === 'QXC') {
        return '取消待确认';
      } else if (row.orderStatus === 'QXS') {
        return '取消成功';
      } else if (row.orderStatus === 'DQS') {
        return '合同未签署';
      } else if (row.orderStatus === 'DFWQ') {
        return '对方未签署';
      }
    },
    async getStatic4OrderAmt() {
      let res = await static4OrderAmt({customer: this.accountType == 'BUY' ? 'buy' : 'sell', tradeRole: this.accountType == 'BUY' ? 'buy' : 'sell'});
      if (res.errno == 0) {
        this.cardData = [
          {
            orderCountSum: 0,
            orderType: '竞买',
            orderAmtSum: 0
          },
          {
            orderCountSum: 0,
            orderType: '竞卖',
            orderAmtSum: 0
          },
          {
            orderCountSum: 0,
            orderType: '竞标',
            orderAmtSum: 0
          }
        ];
        switch (res.data.length) {
          case 1:
            if (res.data[0].orderType === '竞买') {
              this.cardData = res.data.concat([
                {
                  orderCountSum: 0,
                  orderType: '竞卖',
                  orderAmtSum: 0
                },
                {
                  orderCountSum: 0,
                  orderType: '竞标',
                  orderAmtSum: 0
                }
              ]);
            }

            if (res.data[0].orderType === '竞卖') {
              this.cardData = res.data.concat([
                {
                  orderCountSum: 0,
                  orderType: '竞买',
                  orderAmtSum: 0
                },
                {
                  orderCountSum: 0,
                  orderType: '竞标',
                  orderAmtSum: 0
                }
              ]);
            }
            if (res.data[0].orderType === '竞标') {
              this.cardData = res.data.concat([
                {
                  orderCountSum: 0,
                  orderType: '竞买',
                  orderAmtSum: 0
                },
                {
                  orderCountSum: 0,
                  orderType: '竞卖',
                  orderAmtSum: 0
                }
              ]);
            }
            break;
          case 2:
            let myArr = res.data.map(item => {
              return item.orderType;
            });
            if (!myArr.includes('竞买')) {
              this.cardData = res.data.concat([
                {
                  orderCountSum: 0,
                  orderType: '竞买',
                  orderAmtSum: 0
                }
              ]);
            }
            if (!myArr.includes('竞卖')) {
              this.cardData = res.data.concat([
                {
                  orderCountSum: 0,
                  orderType: '竞卖',
                  orderAmtSum: 0
                }
              ]);
            }
            if (!myArr.includes('竞标')) {
              this.cardData = res.data.concat([
                {
                  orderCountSum: 0,
                  orderType: '竞标',
                  orderAmtSum: 0
                }
              ]);
            }
            break;
          case 3:
            this.cardData = res.data;
            break;
        }
      }
    },
    async getMessgeList() {
      let res = await getMessgeList({customer: this.accountType, rows: 3, page: 1});
      if (res.errno == 0) {
        this.msgData = res.data.list;
      }
    },
    async getMybuyderOrderList() {
      let res = await mybuyderOrderList({limt: 5, page: 1});
      if (res.errno == 0) {
        this.taskData = res.data.orderList;
        this.taskData.map(item => {
          item.actualPrice = (parseFloat(item.actualPrice).toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
          return item;
        });
      }
    },
    async getMysellerOrderList() {
      let res = await mysellerOrderList({limt: 5, page: 1});
      if (res.errno == 0) {
        this.taskData = res.data.orderList;
        this.taskData.map(item => {
          item.actualPrice = (parseFloat(item.actualPrice).toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
          return item;
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.workbench {
  &-content {
    display: flex;
    justify-content: space-between;
  }
  .my-card {
    border-radius: 10px;
    box-shadow: 1px 3px 10px rgba(46, 110, 175, 0.2);
  }
  &-cart {
    .card-buy0 {
      background: url('../image/card-buy1.png') no-repeat center/350px 200px;
    }
    .card-buy1 {
      background: url('../image/card-buy2.png') no-repeat center/350px 200px;
    }
    .card-buy2 {
      background: url('../image/card-buy3.png') no-repeat center/350px 200px;
    }
    li {
      text-align: right;
      color: #fff;
      float: left;
      width: 350px;
      height: 200px;
      margin-right: 40px;
      padding: 30px 24px 27px 0;
      &:last-child {
        margin: 0;
      }
    }
    .num {
      font-family: 'DOUYU';
      font-size: 27px;
      line-height: 20px;
    }
    .type {
      opacity: 0.8;
      margin: 10px 0 40px 0;
      font-size: 15px;
    }
    .price {
      font-family: 'DOUYU';
      font-size: 27px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }
  &-flower {
    border-radius: 10px;
    box-shadow: 1px 3px 10px rgba(46, 110, 175, 0.2);
    padding-left: 70px;
    color: #3e4767;
    width: 410px;
    height: 200px;
    background: url('../image/flower.png') no-repeat center/410px 200px;
    .day {
      font-weight: 600;
      font-size: 100px;
      margin-top: 20px;
    }
    p {
      padding-left: 13px;
      font-size: 18px;
    }
  }
  .task-content {
    margin-top: 35px;
    display: flex;
    justify-content: space-between;

    .listMore {
      width: 68px;
      height: 30px;
      padding: 4px 0 0 10px;
      margin-right: 30px;
      background-color: #eff6ff;
      border-radius: 25px;
      float: right;
      font-size: 18px;
      font-weight: 400;
      color: #62afff;
      cursor: pointer;
    }
  }
  .task-left {
    & /deep/ .task-row {
      height: 68px;
      font-size: 17px;
      &:nth-child(odd) {
        background: linear-gradient(to right, #f0f8fe, #fff);
      }
    }
    & /deep/ td,
    & /deep/ th {
      border: none;
    }
    border-radius: 5px;
    width: 1132px;
    padding: 27px;
    background-color: #fff;
    p {
      margin-bottom: 15px;
      font-size: 20px;
      font-weight: 600;
      color: #3d4666;
      span {
        color: #ed3856;
      }
    }
    .left-table {
      height: 388px;
      &::before {
        height: 0;
      }
    }
    & /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
      background: none;
    }
  }
  .BUY /deep/ .el-table--enable-row-hover .el-table__body tr:hover {
    background: linear-gradient(to right, #ecf6fe, #0c95fe);
  }
  .SELL /deep/ .el-table--enable-row-hover .el-table__body tr:hover {
    background: linear-gradient(to right, #ecf3fe, #5d5dd2);
  }
  .task-right {
    width: 410px;
    // 未读消息的红点提示
    .round::before {
      content: '';
      position: absolute;
      left: 12px;
      top: 20px;
      width: 5px;
      height: 5px;
      background-color: red;
      border-radius: 50%;
    }

    ul {
      padding-top: 12px;
      height: 100%;
      border-radius: 5px;
      background-color: #fff;
    }
    li {
      position: relative;
      padding: 15px 25px;
      border-bottom: 1px solid #e7eef7;
      .title {
        display: flex;
        justify-content: space-between;
        color: #3e4767;
        font-size: 20px;
        font-weight: 600;
      }
      .right-more {
        cursor: pointer;
        font-weight: normal;
        font-size: 18px;
        color: #4ca4ff;
        width: 68px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 20px;
        background-color: #eff6ff;
      }
      .time {
        font-size: 17px;
        margin-bottom: 5px;
        color: #a7b1c1;
      }
      .actr {
        font-size: 17px;
        color: #3e4768;
      }
      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      &:last-child {
        border-bottom: none;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}
</style>
